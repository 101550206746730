.footer {
  display: flex;
  justify-content: space-between;
  color: white;
  margin-bottom: 70px;
}

.footer-container {
  position: relative;
  padding-top: 150px;
  z-index: 5;
  background-color: #000;
}

.footer-container p, .footer-container p a {
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
    color: rgb(148 163 184);
}

.footer-container p a {
  cursor: pointer;
  text-decoration: underline;
}

.footer-section {
  width: fit-content;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-section a {
  color: #2d2d2d;
}

.footer-section ul li {
  margin: 30px 0;
}

.footer-section ul li a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer_branding-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 30px;
  border-top: 1px solid;
  border-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, .2) 30%, rgba(255, 255, 255, .2) 70%, rgba(0, 0, 0, 0) 100%) 1;
}

.footer_branding-section img {
  width: 80px;
  margin-right: 20px;
}

.footer_branding-section_left {
  display: flex;
  flex: 1;
  align-items: center;
}

.footer_branding-section_right {
flex: 1;
  text-align: right;
}

.footer_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin-bottom: 60px;
}

.footer_icons a.disabled {
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
}

.footer_icon img {
  height: 30px;
}

@media (max-width: 760px) {
  .footer {
    flex-wrap: wrap;
  }

  .footer-section {
    width: 50%;
    text-align: center;
  }

  .footer-section:nth-child(n + 3) {
    margin-top: 40px;
  }
}

@media (max-width: 670px) {
  .footer_branding-section {
    justify-content: center;
  }

  .footer_branding-section_left {
    max-width: 400px;
  }

  .footer_branding-section_right {
    display: none;
  }
}


@media (max-width: 530px) {
  .footer_icons {
    gap: 30px;
  }
}


@media (max-width: 400px) {
  .footer-section {
    width: 100%;
  }

  .footer_icon {
    height: 20px;
  }
}


@media (max-width: 350px) {
  .footer_icons {
    gap: 20px;
  }
}