@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono&display=swap');

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
  color: #fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    width: 1170px;
    max-width: 95vw;
    margin: 0 auto;
}

.logo {
    max-width: 70px;
}

p {
    line-height: 1.9rem;
    color: rgb(148 163 184);
    font-weight: 500;
}

ul {
    list-style: none;
}

.app {
  font-family: Arial, sans-serif;
    background-color: #000;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

nav {
    width: 100%;
    padding: 20px 0;
}

nav ul, nav .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav a {
  margin: 0 20px;
  text-decoration: none;
  color: #fff;
}

.burger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
    z-index: 6;
}

.burger-menu .bar {
  background-color: #fff;
  height: 2px;
  width: 25px;
}

.nav-list {
  transition: all 0.3s ease-out;
}

.nav-list .nav-list-link {
  text-shadow: 3px 3px 20px #000;
    position: relative;
}

.nav-list .nav-list-link::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: width .5s ease;
}

.nav-list .nav-list-link:hover {
    cursor: pointer;
}

.nav-list .nav-list-link:hover::after {
    width: 100%;
}

.nav-list .button, .nav-list .logo {
  box-shadow: 3px 3px 20px #000;
}

.nav-list .disabled, .nav-list-mobile .disabled {
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

    background-color: rgb(0, 0, 0);
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    transition: opacity .1s linear;
}


h1 {
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: 900;
}

h2 {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 700;
}

h3 {
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 600;
}

h4 {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 600;
    color: rgb(148 163 184);
}

.intro {
    position: relative;
    margin-top: 150px;
    padding: 20px;
    text-align: center;
    padding-bottom: 200px;
}

.intro h4, .intro h1 {
    text-shadow: 3px 3px 20px #000;
}

.intro .button {
    box-shadow: 3px 3px 20px #000;
}

.intro h4, .section-switcher-container h4:first-of-type {
    width: 500px;
    max-width: 95%;
    margin: 15px auto 30px;
}

.section-switcher-container h4:first-of-type {
    width: 600px;
    margin: 5px auto 15px;
}

.stats {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.features {
  padding: 20px 0;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features li {
  margin: 10px 0;
}

.button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    padding: .4rem 1rem;
    border-radius: 5rem;
    background: transparent;
    color: white;
    border: 2px solid rgba(255, 255, 255, .2);
    min-width: 100px;
    text-decoration: none;

  text-shadow: 3px 3px 20px #000;
}

.intro-buttons {
    display: flex;
    justify-content: center;
    margin: 30px auto 0;
    gap: 20px;
    flex-wrap: wrap;
}

.intro-buttons .button {
    width: 300px;
    padding: 1rem 1rem;
}


.button-primary {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 90%);
    color: #fff;
    transition: all .5s ease;
    font-weight: 500;
}

.button-primary:hover {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, #ccc 0%, #fff 90%);
    color: #000;
}

.button-secondary {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 90%);
    color: #fff;
    transition: all .5s ease;
}

.button-secondary:hover {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, #ccc 0%, #fff 90%);
    color: #000;
}

.nav-list-mobile {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}


.title-desktop {
    display: block;
}
.title-mobile {
    display: none;
}

.cross {
    font-size: 30px;
}

.revshare {
    text-align: center;
    margin-bottom: 100px;
}


@media (max-width: 790px) {
  .burger-menu {
    display: flex;
  }

  .nav-list {
    position: fixed;
    top: -100%;
    right: 0;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    background-color: #333;
    padding-top: 50vh;
    justify-content: flex-start;
    transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
  }

  .nav-list.show {
    transform: translateY(0);
  }

  .nav-list li {
    text-align: center;
  }

  .overlay {
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  .overlay.show {
      opacity: 1;
      visibility: visible;
  }

    .nav-list-mobile.show {
        display: flex;
        flex-direction: column;
        z-index: 5;
    }

    .nav-list-mobile li {
        margin: 20px 0;
    }

    .nav-list-mobile .nav-list-link {
        text-decoration: none;
        font-size: 1.5rem;
    }

    .nav-list-mobile .button {
        padding: 10px 20px;
        font-weight: 600;
    }

    .nav-list-mobile li {
        opacity: 0;
        transform: translateY(30px);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .nav-list-mobile.show li {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 525px) {
    .title-desktop {
        display: none;
    }
    .title-mobile {
        display: block;
    }

    .intro {
        padding-top: 30px;
    }

    .intro-buttons {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
}