.revshare_list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.revshare_item {
    padding: 20px 10px;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 5px;
    max-width: 250px;
    min-width: 250px;
}

.revshare_item:not(:last-of-type) {
    margin-right: 20px;
}

.revshare_item.no-max-width {
    max-width: unset;
    border: none;
}

.revshare_item.no-max-width h2 {
    font-size: 0.8rem;
}

.revshare_wallets-button {
    display: inline-block;
    background-color: rgba(255, 255, 255, .1);
    padding: 5px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, .5);
    margin-top: 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

.wallet-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    z-index: 9;
    cursor: pointer;
}

.wallet-popup {
    position: fixed;
    top: 5vh;
    left: 5vw;
    width: 90vw;
    height: 90vh;
    overflow: scroll;
    z-index: 10;
    background-color: #000;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 20px;
}

.wallet-popup-item {
    margin-top: 10px;
}

@keyframes loadingGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading-box {
  width: 100%;
  height: 40px;
  background: linear-gradient(270deg, #000, #808080, #000);
  background-size: 200% 200%;
  animation: loadingGradient 2s infinite;
  border-radius: 4px;
}

@media (max-width: 805px) {
    .revshare_list {
        flex-direction: column;
        align-items: center;
    }

    .revshare_item:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 30px;
    }
}