.alternating-sections {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000;
  z-index: 5;
}

.section {
  display: flex;
  gap: 20px;
  align-items: center;
}

.section:not(:last-of-type) {
  margin-bottom: 50px;
}

.text-left .text-content {
  order: 1;
}

.text-right .text-content {
  order: 2;
}

.text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text-content a {
  margin-top: 10px;
  max-width: fit-content;
  position: relative;
  display: inline;
  text-decoration: none;
}

.text-content a::before {
  position: absolute;
  content: "";
  bottom: -5px;
  left: 0;
  right: 0;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #fff, rgba(0, 0, 0, 0) 85%);
  transform: translateX(0%);
}

.video-content {
  flex: 1;
}

.video-content video {
  width: 100%;
  height: auto;
  max-height: 400px;
}

.section:nth-child(1) .text-content,
.section:nth-child(3) .text-content,
.section:nth-child(5) .text-content {
  order: 1;
}

.section:nth-child(1) .video-content,
.section:nth-child(3) .video-content,
.section:nth-child(5) .video-content {
  order: 2;
}

.section:nth-child(2) .text-content,
.section:nth-child(4) .text-content,
.section:nth-child(6) .text-content {
  order: 2;
}

.section:nth-child(2) .video-content,
.section:nth-child(4) .video-content,
.section:nth-child(6) .video-content {
  order: 1;
}

.text-content_links  {
  margin-top: 10px;
}

.text-content_links a:not(:last-of-type) {
  margin-right: 30px;
}

@media (max-width: 890px) {
  .section {
    width: 80%;
    margin: 0 auto;
  }

  .section:nth-child(1), .section:nth-child(3), .section:nth-child(5) {
      flex-direction: column-reverse;
  }

  .section:nth-child(2),.section:nth-child(4),.section:nth-child(6) {
      flex-direction: column;
  }
}

@media (max-width: 600px) {
  .section {
    width: 95%;
  }
}